import { Avatar } from "@cpchem/covalence-ui";
import { faArrowAltRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HistoryLogViewTestIds } from "@pages/tag-management-page/test-ids";
import { Activity, Change } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useEffect, useState } from "react";
import { getOtherGraphUserPhoto } from "../../../../app/components/graph-photo/graph-user-photo";
import { getInitialsFromNameString } from "../../../../app/components/template-app-bar/avatar-content/user-initials-from-name-string";
import "./styles.scss";

interface LogCommentViewProps {
    activity: Activity;
}
export const LogCommentView = ({ activity }: LogCommentViewProps) => {
    const [activityText, setActivityText] = useState("");
    const [changeText, setChangeText] = useState("");
    const [reasonForChange, setReasonForChange] = useState("");
    const [isRequestComment, setIsRequestComment] = useState(false);
    const changeLog = activity.log;
    const [changes, setChanges] = useState<Change[]>([]);

    const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>("");
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;

    async function loadPhoto() {
        if (profilePhoto === null) {
            const photo = activity?.commentedByEmail
                ? await getOtherGraphUserPhoto(activity.commentedByEmail)
                : null;

            setProfilePhoto(photo ?? null);
        }
        if (userInitials === "") {
            const parsedInitials = getInitialsFromNameString(
                activity?.commentedBy
            );
            if (parsedInitials !== userInitials) {
                setUserInitials(parsedInitials);
            }
        }
    }

    useEffect(() => {
        if (activity.logType === "confirmed") {
            setActivityText("made a change.");
            setChangeText("Tag status set to confirmed.");
        } else if (activity.logType === "pending") {
            if (typeof changeLog === "string" && changeLog === "") {
                setActivityText("made a change.");
                setChangeText("Tag status set to pending.");
            } else if (typeof changeLog === "object" && changeLog !== null) {
                setActivityText("made a change.");
                setReasonForChange(changeLog.ReasonForChange);
            }
        } else if (activity.logType === "modified") {
            if (typeof changeLog === "object" && changeLog !== null) {
                setActivityText("made a change.");
                setReasonForChange(changeLog.ReasonForChange);
                if (changeLog.TagUpdates !== null)
                    setChanges(changeLog.TagUpdates);
            }
        } else if (activity.logType === "removed") {
            if (typeof changeLog === "object" && changeLog !== null) {
                setActivityText("made a change.");
                setReasonForChange(changeLog.ReasonForChange);
            }
        } else if (
            activity.logType === "commented" ||
            activity.logType === "closed"
        ) {
            if (typeof changeLog === "object" && changeLog !== null) {
                setIsRequestComment(true);
                setActivityText("left a Note.");
                setReasonForChange(changeLog.ReasonForChange);
            }
        }
    }, [activity, changeLog, changes, reasonForChange]);

    useEffect(() => {
        loadPhoto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagRequest, dispatch]);

    return (
        <>
            <div className="history-log-avatar">
                {profilePhoto && (
                    <Avatar
                        alt={userInitials}
                        src={profilePhoto || ""}
                        className="avatar"
                        testId={HistoryLogViewTestIds.HistoryLogAvatar}
                    />
                )}
                {!profilePhoto && (
                    <Avatar
                        alt={userInitials}
                        initials={userInitials}
                        className="avatar"
                        testId={HistoryLogViewTestIds.HistoryLogAvatar}
                    />
                )}
            </div>
            {isRequestComment && (
                <div
                    className="request-change-submitter"
                    data-testid={HistoryLogViewTestIds.ChangeSubmitter}
                >
                    {activity.commentedBy}
                </div>
            )}
            {!isRequestComment && (
                <div
                    className="activity"
                    data-testid={HistoryLogViewTestIds.Activity}
                >
                    <div
                        className="activity-text"
                        data-testid={HistoryLogViewTestIds.ActivityText}
                    >
                        <p className="tag-change-submitter">
                            {activity.commentedBy}
                        </p>
                        {activityText}
                    </div>
                    {changes.length > 0 && (
                        <div
                            className="activity-changes"
                            data-testid={HistoryLogViewTestIds.ActivityChanges}
                        >
                            <p className="changes">Changes:</p>
                            <ul
                                data-testid={
                                    HistoryLogViewTestIds.ActivityChangesList
                                }
                            >
                                {changes.map((change) => {
                                    return (
                                        <li key={change.Name}>
                                            <div
                                                className="change"
                                                data-testid={
                                                    HistoryLogViewTestIds.ChangeName
                                                }
                                            >
                                                {change.Name === "frequencyMode"
                                                    ? "Frequency Mode"
                                                    : "Pull Frequency"}
                                                : {change.From}{" "}
                                                <FontAwesomeIcon
                                                    icon={faArrowAltRight}
                                                    size="sm"
                                                    data-testid=""
                                                />{" "}
                                                {change.To}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {changeText.length > 0 && (
                        <div className="change-text">{changeText}</div>
                    )}
                    {reasonForChange.length > 0 && (
                        <div className="reason-for-change">
                            Reason For Change: {reasonForChange}
                        </div>
                    )}
                </div>
            )}
            {isRequestComment && (
                <div className="reason-for-change">{reasonForChange}</div>
            )}
        </>
    );
};
