import { LoadingDisplay } from "@components/loading";
import { Button, TextField, useToast } from "@cpchem/covalence-ui";
import { ApprovalPreviewTestIds } from "@pages/tag-management-page/test-ids";
import { useTagManagement } from "@stores/tag-management-context";
import { scrollToElement } from "@utilities/scroll-into-view";
import React, { useState } from "react";
import { HistoryLogView } from "../history-log-view";
import { RequestorView } from "../requestor-view";
import "./styles.scss";

interface RequestHistoryViewProps {
    closeRequest: (action: string, requestComment: string) => void;
    saveComment: (action: string, requestComment: string) => void;
    hideRequestComment: boolean;
    isRequestCommentLoading: boolean;
}
export const RequestHistoryView = ({
    closeRequest,
    saveComment,
    hideRequestComment,
    isRequestCommentLoading
}: RequestHistoryViewProps) => {
    const [requestComment, setRequestComment] = useState("");
    const { createToast } = useToast();
    const { state } = useTagManagement();
    const { requestLogHistory } = state;
    const onCommentEntered = (newValue: string) => {
        setRequestComment(newValue);
    };

    function displayCommentRequiredToast() {
        createToast(`Please enter a comment!`, {
            id: `enter-comment-toast`,
            severity: "danger",
            autoDismiss: false,
            autoDismissDelay: 10000
        });
    }

    const closeRequestAction = (action: string, requestComment: string) => {
        if (requestComment === "") {
            displayCommentRequiredToast();
        } else {
            closeRequest(action, requestComment);
            setRequestComment("");
            scrollToElement("save-comment-panel");
        }
    };

    const saveCommentAction = (action: string, requestComment: string) => {
        if (requestComment === "") {
            displayCommentRequiredToast();
        } else {
            saveComment(action, requestComment);
            setRequestComment("");
        }
    };

    return (
        <>
            <div
                className="history-log-title"
                data-testid={ApprovalPreviewTestIds.HistoryLogTitle}
            >
                History Log
            </div>
            <div className="history-log-comment-requestor-view">
                <div className="history-log-comment-view">
                    <HistoryLogView changeLogHistory={requestLogHistory} />
                    {!hideRequestComment && (
                        <div className="request-comment">
                            <LoadingDisplay
                                className="request-comment-loading-display"
                                showLoading={isRequestCommentLoading}
                            />
                            <TextField
                                placeholder="Enter Comment Here..."
                                onChange={onCommentEntered}
                                label="Add a Comment..."
                                value={requestComment}
                                isMultiline
                                isResizable
                                testId={
                                    ApprovalPreviewTestIds.AddRequestComment
                                }
                            />
                            <div className="request-comment-panel-buttons">
                                <div className="close-request-panel-button">
                                    <Button
                                        variant="solid"
                                        size="medium"
                                        text="Close Request With Comment"
                                        className="close-request-with-comment"
                                        color="danger"
                                        onClick={() =>
                                            closeRequestAction(
                                                "closed",
                                                requestComment
                                            )
                                        }
                                        testId={
                                            ApprovalPreviewTestIds.CloseRequestWithComment
                                        }
                                    />
                                </div>
                                <div
                                    id="save-comment-panel"
                                    className="save-comment-panel-button"
                                >
                                    <Button
                                        variant="solid"
                                        size="medium"
                                        text="Comment"
                                        className="save-comment"
                                        color="primary"
                                        onClick={() =>
                                            saveCommentAction(
                                                "commented",
                                                requestComment
                                            )
                                        }
                                        testId={ApprovalPreviewTestIds.Comment}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="requestor-view">
                    <RequestorView />
                </div>
            </div>
        </>
    );
};
